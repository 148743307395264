import React, { useState } from 'react';
import { Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import makeStyles from "@material-ui/core/styles/makeStyles";

import BG_IMG from '../media/bg.jpg';
import { ReactComponent as MYSWANLOGO } from '../media/myswan.svg';
import { ReactComponent as ANDROIDBUTTON } from '../media/androidbutton.svg';
import { ReactComponent as APPLEBUTTON } from '../media/applebutton.svg';
import { ReactComponent as SIGNUPAPPLE } from '../media/signupapple.svg';


const useStyles = makeStyles((theme) => ({
  input: { width: '100%', border: 0, outline: 'none', fontFamily: 'Glober', padding: 10, fontSize: 18, borderRadius: 5, paddingLeft: 15 },
  firstNameWrapper: { backgroundColor: 'white', width: '85%', height: 70, display: 'flex', margin: 'auto', borderRadius: 5, marginTop: 40 },
  lastNameWrapper: { backgroundColor: 'white', width: '85%', height: 70, display: 'flex', margin: 'auto', borderRadius: 5, marginTop: 15 },
  appleIdWrapper: { backgroundColor: 'white', width: '85%', height: 70, display: 'flex', margin: 'auto', borderRadius: 5, marginTop: 15 },
  submitWrapper: { backgroundColor: 'black', width: '60%', height: 70, display: 'flex', margin: 'auto', borderRadius: 5, marginTop: 35 },
  submit: { fontSize: 28, color: 'white', fontFamily: 'Glober', textAlign: 'center', margin: 'auto', display: 'flex' },
  errorWrapper: { width: '85%', margin: 'auto', borderRadius: 5, marginTop: 5, color: 'red' },
  copyright: { fontFamily: 'Glober', fontSize: 14, margin: 'auto', textAlign: 'center', marginTop: 40 },
  subTitle: { fontFamily: 'GloberSemiBold', margin: 'auto', display: 'flex', paddingTop: 55, color: 'black', fontSize: 20, width: '75%', textAlign: 'center' }
}));


function LandingPage() {
  const testAPKUrl = 'http://s.imova.com.ua/myswanandroidpreprod';
  const [localState, setLocalState] = useState({ isIOS: false, showDialog: false, isResponseError: false });
  const [errorText, setErrorText] = useState('');
  const { handleSubmit, reset, register, formState: { errors } } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      appleId: ''
    }
  });
  const classes = useStyles();

  function onAndroidClick() {
    const timestamp = Date.now();
    const url = `${testAPKUrl}?timestamp=${timestamp}`;
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
  }

  function onIOSClick() {
    setLocalState({ ...localState, isIOS: true });
  }

  async function submitForm(data) {
    // console.log("FORM DATA", data)
    try {
      const response = await fetch('https://preprod-swan-sl-api.idin.tech/registrations', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      if (!response.ok) {
        const data = await response.json()
        setErrorText(data.msg)
        // setLocalState({ ...localState, isResponseError: true, showDialog: true })
        setLocalState({ ...localState, isIOS: false })
        return
      }
      // setLocalState({ ...localState, isResponseError: false, showDialog: true })
      setLocalState({ ...localState, isIOS: false })
      reset();
    } catch (error) {
      console.log("submitForm error", error);
    }
  }

  return (
    <div style={{
      backgroundImage: `url(${BG_IMG})`,
      backgroundSize: 'cover',
      textSize: "126.3sp",
      height: '100vh',
      width: '100vw'
    }}>

      {!localState.isIOS &&
      <div>
        <MYSWANLOGO style={{ margin: 'auto', display: 'flex', paddingTop: 55 }} />
        <Typography className={classes.subTitle}>Choose an option below based on your phone model.</Typography>
        <ANDROIDBUTTON onClick={() => onAndroidClick()} style={{ margin: 'auto', display: 'flex', marginTop: 20 }} />
        <APPLEBUTTON onClick={() => onIOSClick()} style={{ margin: 'auto', display: 'flex', marginTop: 10 }} />
        <Typography className={classes.copyright}>© 2021 SWAN. All rights reserved</Typography>
      </div>
      }
      {localState.isIOS &&
      <div>
        <SIGNUPAPPLE style={{ margin: 'auto', display: 'flex', paddingTop: 120 }} />
        <div className={classes.firstNameWrapper}>
          <input placeholder={'FIRST NAME'}
                 className={classes.input}
                 {...register("firstName", { required: "First Name is required" })}
          />
        </div>
        <div className={classes.errorWrapper}>{errors.firstName?.type === 'required' && errors.firstName.message}</div>
        <div className={classes.lastNameWrapper}>
          <input placeholder={'LAST NAME'}
                 className={classes.input}
                 {...register("lastName", { required: "Last Name is required" })}
          />
        </div>
        <div className={classes.errorWrapper}>{errors.lastName?.type === 'required' && errors.lastName.message}</div>
        <div className={classes.appleIdWrapper}>
          <input placeholder={'APPLE ID (Device Email)'}
                 className={classes.input}
                 {...register("appleId", {
                   required: "Apple ID is required",
                   pattern: {
                     value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                     message: "Invalid email format"
                   },
                 })}
          />
        </div>
        <div className={classes.errorWrapper}>
          {errors.appleId?.type === 'required' && errors.appleId.message}
          {errors.appleId?.type === 'pattern' && errors.appleId.message}
        </div>
        <div className={classes.submitWrapper}
             onClick={handleSubmit(submitForm)}>
          <Typography className={classes.submit}>SUBMIT</Typography>
        </div>
        <Typography className={classes.copyright}>© 2021 SWAN. All rights reserved</Typography>
      </div>}
    </div>
  )
}

export default LandingPage
